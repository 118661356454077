import moment from 'moment';

const formatters = {
    DateFormatter: (params) => {
        return params ? moment(params).format('YYYY-MM-DD') : '';
    },
    YearFormatter: (params) => {
        return params ? moment(params).format('DD/MM/YYYY') : '--:--';
    },
    MonthShortFormatter: (params) => {
        return params ? moment(params).format('DD-MMM-YYYY') : '-';
    },
    DateTimeFormatter: (params) => {
        return params ? moment(params).format('DD-MMM-YYYY HH:mm') : '';
    },
    DateTimeTHMSFormatter: (params) => {
        return params ? moment(params).format('YYYY-MM-DDTHH:mm') : '';
    },
    CurrencyThousandSeparator: (params) => {
        let param = Math.trunc(params);
        if (param >= 0) {
            return params ? `£${params.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
        } else {
            return params
                ? `-£${Math.abs(params)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
                : '£0.00';
        }
    },
    ThousandSeparator: (params) => {
        return params ? `${params.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '0.00';
    },

    CurrencyThousandSeparatorWithoutZero: (params) => {
        let param = Math.trunc(params);
        if (param >= 0) {
            return params ? `£${param.toLocaleString()}` : '£0';
        } else {
            return params ? `-£${Math.abs(param).toLocaleString()}` : '£0';
        }
    },

    ThousandSeparatorWithoutZero: (params) => {
        let param = Math.trunc(params);
        return params ? `${param.toLocaleString()}` : '0';
    },

    ThousandWithKWithoutZero: (params) => {
        return params ? `£${Math.abs(params) > 999 ? Math.sign(params)*((Math.abs(params)/1000).toFixed(1)) + 'k' : Math.sign(params)*Math.abs(params)}` : '0';
    },

    MillionWithMWithoutZero: (params) => {
        return params ? `£${Math.abs(params) > 999999 ? Math.sign(params)*((Math.abs(params)/1000000).toFixed(1)) + 'm' : Math.abs(params) > 999 ? Math.sign(params)*((Math.abs(params)/1000).toFixed(1)) + 'k' : Math.sign(params)*Math.abs(params)}` : '0';
    },

    ThousandSeparatorWithRoundnWithoutZero: (params) => {
        let param = Math.round(params);
        return params ? `${param.toLocaleString()}` : '0';
    },

    constantMonthList: (params) => {
        return [
            { description: 'January', id: '01' },
            { description: 'February', id: '02' },
            { description: 'March', id: '03' },
            { description: 'April', id: '04' },
            { description: 'May', id: '05' },
            { description: 'June', id: '06' },
            { description: 'July', id: '07' },
            { description: 'August', id: '08' },
            { description: 'September', id: '09' },
            { description: 'October', id: '10' },
            { description: 'November', id: '11' },
            { description: 'December', id: '12' }
        ];
    },
    ToFixedSeparator: (params) => {
        return params ? `${params.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '0.00';
    }
};

export default formatters;
