import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect } from 'react';

export default (props) => {
    return <TextField margin="dense" autoComplete="off" required fullWidth variant="outlined" {...props} />;
};

export const TextFieldIcon = (props) => {
    return (
        <div className='textFielWithIcon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
            <path d="M14.4855 14.6L18 18M8.89286 4.64286C10.9048 4.64286 12.5357 6.27382 12.5357 8.28571M16.8667 8.93333C16.8667 13.3148 13.3148 16.8667 8.93333 16.8667C4.55187 16.8667 1 13.3148 1 8.93333C1 4.55187 4.55187 1 8.93333 1C13.3148 1 16.8667 4.55187 16.8667 8.93333Z" stroke="#C8C8C8" strokeWidth="2" strokeLinecap="round"/>
            </svg>
            <TextField margin="dense" autoComplete="off" required fullWidth variant="outlined" {...props} />
        </div>
    );
};
export const DatePicker = (props) => {
    const { value, onChange, isDisabled, inputLabel, isRequired, ...rest } = props;

    let [state, setState] = useState({ date: undefined });

    useEffect(() => {
        setState((st) => ({ ...st, date: props.value == 'Invalid date' ? '--' : props.value }));
    }, [props.value]);
    const handeDateChange = (e) => {
        const { value } = e.target;
        if (value < '2099-12-31') {
            setState((st) => ({ ...st, date: value }));
            props.onChange(e);
        }
    };
    return (
        <TextField
            id="date"
            size="small"
            variant="outlined"
            type="date"
            fullWidth
            inputProps={{
                max: '2099-12-31'
            }}
            onChange={handeDateChange}
            value={state.date}
            {...rest}
            InputLabelProps={{
                shrink: true
            }}
        />
    );
};
export const TextBoxShrink = (props) => {
    return <TextField margin="dense" autoComplete="off" required fullWidth variant="outlined" InputLabelProps={{ shrink: true }} {...props} />;
};
export const DateTimePicker = (props) => {
    const { value, onChange, ...rest } = props;

    let [state, setState] = useState({ date: null });

    useEffect(() => {
        setState((st) => ({ ...st, date: props.value }));
    }, [props.value]);

    const handeDateChange = (e) => {
        const { value } = e.target;
        if (value < '2099-12-31T00:00') {
            setState((st) => ({ ...st, date: value }));
            props.onChange(e);
        }
    };
    return (
        <TextField
            id="datetime-local"
            size="small"
            variant="outlined"
            type="datetime-local"
            fullWidth
            inputProps={{
                max: '2099-12-31T00:00'
            }}
            onChange={handeDateChange}
            value={state.date}
            {...rest}
            InputLabelProps={{
                shrink: true
            }}
        />
    );
};
